import Head from "next/head";

import Logo from "@/components/icons/Logo";

export default function Login() {
  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-50 px-8">
      <Head>
        <title>Login</title>
      </Head>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="flex flex-col items-center space-y-4 bg-white px-4 py-8 shadow-xs sm:rounded-lg sm:px-10">
          <div className="text-blue text-4xl font-bold">
            <Logo className="-ml-3 h-3" />
            MOCO Baustellentool
          </div>
          <a
            className="flex w-full max-w-[50%] justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-2xs hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:outline-hidden"
            href="/backend/oidc/authenticate/"
          >
            Anmelden
          </a>
        </div>
      </div>
    </div>
  );
}
